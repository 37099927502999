import React from 'react'
import PropTypes from 'prop-types'

import { FaqSection } from '@saatva-bits/pattern-library.modules.faq'

import QuizOnRamp from '@/components/QuizOnRamp'
import WhySaatva from '@/components/WhySaatva'
import DetailProductTileSlider from '@/components/DetailProductTileSlider'
import ValuePropsSection from '@/components/ValuePropsSection'
import ExtendOnRamp from '@/components/ExtendOnRamp'
import Quiz from '@/components/QuizMattress'
import ReviewSection from '@/components/ReviewSection/ReviewSection'
import SupportValueProps from '@/components/SupportValueProps/SupportValueProps'

import ShopTheRoom from './ShopTheRoom/ShopTheRoom'

const MattressesContent = ({ category, recommendationData, reviews }) => {
    return (
        <>
            <Quiz />
            <QuizOnRamp showQuizCTA={false} />
            <ValuePropsSection configKey={category} background="contrast-1" />
            <SupportValueProps />
            <ReviewSection reviews={reviews} />
            <WhySaatva />
            <DetailProductTileSlider
                products={recommendationData}
                title="Our best-selling furniture & bedding"
                dataSelector="mattress-plp-recommended-products"
                sectionClassName="u-hidden--lg-down"
            />
            <ShopTheRoom sectionClassName="u-hidden--lg-up" />
            <FaqSection />
            <ExtendOnRamp sectionClassName="u-paddingTop--none" />
        </>
    )
}

MattressesContent.propTypes = {
    category: PropTypes.string.isRequired,
    recommendationData: PropTypes.array
}

export default MattressesContent
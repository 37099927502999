import productCodes from '../../../../temp-configs/product-codes'

const carouselData = {
    // Furniture Products
    [`${productCodes.adjustableBase}`]: {
        altTag: 'Saatva Adjustable Base',
        attributeSets: [], // empty array means no variation in imagery besides descriptor
        imageDescriptors: [
            // Currently, this product is set to show 5 images for each variant,
            // but this is accomplished by using 6 items and exclusions.
            'model',
            {
                descriptorName: 'angle',
                attributeSets: ['splitType']
            },
            {
                descriptorName: 'side',
                attributeSets: ['splitType']
            },
            {
                descriptorName: 'front',
                attributeSets: ['splitType'],
                exclusions: {
                    splitType: 'standard' // front image will not display for standard mattresses
                }
            },
            {
                descriptorName: 'silo',
                attributeSets: ['mattressSize'] // also follows order in array
            },
            'remote'
        ]
    },
    [`${productCodes.adjustableBasePlus}`]: {
        altTag: 'Saatva Adjustable Base Plus',
        attributeSets: [],
        imageDescriptors: [
            'model-sitting', // defaults to just a descriptor string
            'model-laying',
            {
                descriptorName: 'silo',
                attributeSets: ['mattressSize'] // also follows order in array
            },
            'remote',
            'couple'
        ]
    },
    [`${productCodes.amalfi}`]: {
        altTag: 'Amalfi',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: ['angle', 'front', 'side']
    },
    [`${productCodes.amalfiPlatformBed}`]: {
        altTag: 'Amalfi Platform Bed',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: ['angle', 'front', 'side']
    },
    [`${productCodes.copenhagen}`]: {
        altTag: 'Copenhagen',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: ['angle', 'front', 'side']
    },
    [`${productCodes.foundation}`]: {
        altTag: 'Saatva Mattress Foundation',
        attributeSets: ['mattressType', 'foundationHeight'],
        imageDescriptors: [
            {
                descriptorName: 'angle',
                attributeSets: ['foundationHeight']
            },
            {
                descriptorName: 'side',
                attributeSets: []
            },
            'front',
            {
                descriptorName: 'detail',
                attributeSets: []
            },
            {
                descriptorName: 'silo',
                attributeSets: []
            }
        ]
    },
    [`${productCodes.halle}`]: {
        altTag: 'Halle',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: ['angle', 'front', 'side']
    },
    [`${productCodes.halleStoragePlatformBed}`]: {
        altTag: 'Halle Storage Platform Bed',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: [
            'room-closed',
            'room-open',
            'room-front',
            'room-side'
        ]
    },
    [`${productCodes.cassisWithStorage}`]: {
        altTag: 'Cassis Storage Bed',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: [
            'room-closed',
            'room-open',
            'room-front',
            'room-side'
        ]
    },
    [`${productCodes.amalfiWithStorage}`]: {
        altTag: 'Amalfi Platform Bed',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: [
            'room-closed',
            'room-open',
            'room-front',
            'room-side'
        ]
    },
    [`${productCodes.lyonWithStorage}`]: {
        altTag: 'Lyon Storage Bed',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: [
            'room-closed',
            'room-open',
            'room-front',
            'room-side'
        ]
    },
    [`${productCodes.minoriWithStorage}`]: {
        altTag: 'Minori Storage Bed',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: [
            'room-closed',
            'room-open',
            'room-front',
            'room-side'
        ]
    },
    [`${productCodes.lucerne}`]: {
        altTag: 'Lucerne',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: ['room-front', 'room-angle', 'room-side']
    },
    [`${productCodes.marbella}`]: {
        altTag: 'Marbella',
        attributeSets: ['sidePanels', 'bedFrameFabric'],
        imageDescriptors: ['angle', 'front', 'side']
    },
    [`${productCodes.marseille}`]: {
        altTag: 'Marseille',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: ['angle', 'front', 'side']
    },
    [`${productCodes.minori}`]: {
        altTag: 'Minori',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: ['angle', 'front', 'side']
    },
    [`${productCodes.porto}`]: {
        altTag: 'Porto',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: ['angle', 'front', 'side']
    },
    [`${productCodes.rhoneStorageBench}`]: {
        altTag: 'Rhone Storage Bench',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: [
            'front',
            'open',
            'side',
            {
                descriptorName: 'swatch',
                attributeSets: []
            },
            'silo-angle'
        ]
    },
    [`${productCodes.santorini}`]: {
        altTag: 'Santorini Bed Frame',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: ['angle', 'front', 'side']
    },
    [`${productCodes.rhodes}`]: {
        altTag: 'Rhodes Platform Bed Frame',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: ['room-angle', 'room-front', 'room-side']
    },
    [`${productCodes.santoriniPlatformBed}`]: {
        altTag: 'Santorini Platform Bed',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: ['angle', 'front', 'side']
    },
    [`${productCodes.siena}`]: {
        altTag: 'Siena',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: ['front', 'angle', 'side']
    },
    [`${productCodes.sydney}`]: {
        altTag: 'Sydney',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: ['angle', 'front', 'side']
    },
    [`${productCodes.valencia}`]: {
        altTag: 'Valencia',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: ['angle', 'front', 'side']
    },
    [`${productCodes.halleWithStorage}`]: {
        altTag: 'Halle with Storage',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: [
            'room-closed',
            'room-open',
            'room-front',
            'room-side'
        ]
    },
    [`${productCodes.cassis}`]: {
        altTag: 'Cassis Platform Bed Frame',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: ['room-angle', 'room-front', 'room-side']
    },
    [`${productCodes.milan}`]: {
        altTag: 'Milan Platform Bed Frame',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: ['room-angle', 'room-front', 'room-side'] // needs to be modify according provided assets
    },
    [`${productCodes.calais}`]: {
        altTag: 'Calais Bed Frame',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: ['room-angle', 'room-front', 'room-side'] // needs to be modify according provided assets
    },
    [`${productCodes.lyon}`]: {
        altTag: 'Lyon Platform Bed Frame',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: ['room-angle', 'room-front', 'room-side']
    },
    [`${productCodes.merano}`]: {
        altTag: 'Merano Platform Bed Frame',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: ['room-angle', 'room-front', 'room-side']
    },
    [`${productCodes.crete}`]: {
        altTag: 'Crete Platform Bed Frame',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: ['room-angle', 'room-front', 'room-side']
    },
    [`${productCodes.adraLeather}`]: {
        altTag: 'Adra Leather Platform Bed Frame',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: ['room-angle', 'room-front', 'room-side']
    },
    [`${productCodes.cologneWood}`]: {
        altTag: 'Cologne Wood Bed Frame',
        attributeSets: ['color'],
        imageDescriptors: ['room-angle', 'room-front', 'room-side']
    },
    [`${productCodes.corsicaWood}`]: {
        altTag: 'Corsica Wood Bed Frame',
        attributeSets: ['color'],
        imageDescriptors: ['room-angle', 'room-front', 'room-side']
    },
    [`${productCodes.materaWood}`]: {
        altTag: 'Matera Wood Bed Frame',
        attributeSets: ['color'],
        imageDescriptors: ['room-angle', 'room-front', 'room-side']
    },
    [`${productCodes.atlasNightstand}`]: {
        altTag: 'Atlas Nightstand',
        attributeSets: ['color'],
        imageDescriptors: [
            'front',
            'room-front',
            'open',
            'angle',
            'side'
        ]    },
    [`${productCodes.leoNightstand}`]: {
        altTag: 'Leo Nightstand',
        attributeSets: ['size'],
        imageDescriptors: [
            'front',
            'room-front',
            'open',
            'angle',
            'side'
        ]    },
    [`${productCodes.noireNightstand}`]: {
        altTag: 'Noire Nightstand',
        attributeSets: ['size'],
        imageDescriptors: [
            'front',
            'room-front',
            'open',
            'angle',
            'side'
        ]
    },
    // candles
    [`${productCodes.scentedCandles}`]: {
        altTag: 'Scented Candles',
        attributeSets: [],
        imageDescriptors: [
            'silo-drift',
            'silo-restore',
            'silo-dream',
            'silo-unwind',
            'lifestyle'
        ]
    },
    [`${productCodes.scentedVotives}`]: {
        altTag: 'Scented Votives',
        attributeSets: [],
        imageDescriptors: ['silo-set', 'box-closed', 'lifestyle']
    },
    // Chairs, Bench, ottoman Products
    [`${productCodes.lenaCushionedBench}`]: {
        altTag: 'Lena',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: [
            'front',
            'angle',
            'side',
            {
                descriptorName: 'swatch',
                attributeSets: []
            },
            'silo-angle'
        ]
    },
    [`${productCodes.loganChair}`]: {
        altTag: 'Logan Chair',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: [
            'studio-angle',
            'studio-front',
            'studio-side',
            {
                descriptorName: 'room',
                attributeSets: []
            },
            {
                descriptorName: 'swatch',
                attributeSets: []
            }
        ]
    },
    [`${productCodes.comoSwivelChair}`]: {
        altTag: 'Como Swivel Chair',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: [
            'studio-angle',
            'studio-front',
            'studio-side',
            {
                descriptorName: 'swatch',
                attributeSets: []
            },
            {
                descriptorName: 'room',
                attributeSets: []
            }
        ]
    },
    [`${productCodes.anaisChair}`]: {
        altTag: 'Anais Chair',
        attributeSets: ['chairOptions', 'bedFrameFabric'], // will likely be color in the new model
        imageDescriptors: [
            'studio-angle',
            'studio-front',
            'studio-side',
            {
                descriptorName: 'room',
                attributeSets: []
            },
            {
                descriptorName: 'swatch',
                attributeSets: []
            }
        ]
    },
    [`${productCodes.lunaSwivelChair}`]: {
        altTag: 'Luna Swivel Chair',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: [
            'studio-angle',
            'studio-front',
            'studio-side',
            {
                descriptorName: 'room-fireplace',
                attributeSets: []
            },
            {
                descriptorName: 'room-window',
                attributeSets: []
            },
            {
                descriptorName: 'swatch',
                attributeSets: []
            }
        ]
    },
    [`${productCodes.arnoCushionedBench}`]: {
        altTag: 'Arno Cushioned Bench',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: [
            'angle',
            'front',
            'side',
            {
                descriptorName: 'swatch',
                attributeSets: []
            },
            'silo-angle'
        ]
    },
    [`${productCodes.brienneChannelOttoman}`]: {
        altTag: 'Brienne Channel Ottoman',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: [
            'angle',
            'front',
            'side',
            {
                descriptorName: 'swatch',
                attributeSets: []
            },
            'silo-angle'
        ]
    },
    [`${productCodes.constanceTuftedOttoman}`]: {
        altTag: 'Constance Tufted Ottoman',
        attributeSets: ['bedFrameFabric'],
        imageDescriptors: [
            'front',
            'angle',
            'side',
            {
                descriptorName: 'swatch',
                attributeSets: []
            },
            'silo-angle'
        ]
    },
    // Rug Products
    [`${productCodes.cirrusRug}`]: {
        altTag: 'Cirrus Bedroom Rug',
        attributeSets: ['color'],
        imageDescriptors: [
            'silo',
            {
                descriptorName: 'room-above',
                attributeSets: []
            },
            {
                descriptorName: 'room-front',
                attributeSets: []
            },
            'detail',
            'back',
            {
                descriptorName: 'colorways',
                attributeSets: []
            }
        ]
    },
    [`${productCodes.kananRug}`]: {
        altTag: 'Kanan Bedroom Rug',
        attributeSets: ['color'],
        imageDescriptors: [
            'silo',
            {
                descriptorName: 'room-above',
                attributeSets: []
            },
            {
                descriptorName: 'room-front',
                attributeSets: []
            },
            'detail',
            'back',
            {
                descriptorName: 'colorways',
                attributeSets: []
            }
        ]
    },
    [`${productCodes.adamasRug}`]: {
        altTag: 'Adamas Bedroom Rug',
        attributeSets: ['color'],
        imageDescriptors: [
            'silo',
            {
                descriptorName: 'room-above',
                attributeSets: []
            },
            {
                descriptorName: 'room-front',
                attributeSets: []
            },
            'detail',
            'back',
            {
                descriptorName: 'colorways',
                attributeSets: []
            }
        ]
    },
    [`${productCodes.ternaRug}`]: {
        altTag: 'Terna Bedroom Rug',
        attributeSets: ['color'],
        imageDescriptors: [
            'silo',
            {
                descriptorName: 'room-above',
                attributeSets: []
            },
            {
                descriptorName: 'room-front',
                attributeSets: []
            },
            'detail',
            'back',
            {
                descriptorName: 'colorways',
                attributeSets: []
            }
        ]
    },
    [`${productCodes.myraRug}`]: {
        altTag: 'Myra Bedroom Rug',
        attributeSets: ['color'],
        imageDescriptors: [
            'silo',
            {
                descriptorName: 'room-above',
                attributeSets: []
            },
            {
                descriptorName: 'room-front',
                attributeSets: []
            },
            'detail',
            'back',
            {
                descriptorName: 'colorways',
                attributeSets: []
            }
        ]
    },
    [`${productCodes.naviRug}`]: {
        altTag: 'Navi Bedroom Rug',
        attributeSets: ['color'],
        imageDescriptors: [
            'silo',
            {
                descriptorName: 'room-above',
                attributeSets: []
            },
            {
                descriptorName: 'room-front',
                attributeSets: []
            },
            'detail',
            'back',
            {
                descriptorName: 'colorways',
                attributeSets: []
            }
        ]
    },
    // Mattress Products
    [`${productCodes.zenhaven}`]: {
        altTag: 'Zenhaven Mattress',
        attributeSets: ['mattressType'],
        imageDescriptors: [
            'room-angle-raised',
            'side',
            'angle',
            {
                descriptorName: 'corner',
                attributeSets: []
            },
            {
                descriptorName: 'model',
                attributeSets: []
            },
            'above'
        ]
    },
    [`${productCodes.classic}`]: {
        altTag: 'Saatva Classic Mattress',
        attributeSets: [],
        imageDescriptors: ['saatvana-angle', 'front', 'layer-diagram', 'lifestyle-bedroom', 'detail', 'above', 'side']
    },
    [`${productCodes.hd}`]: {
        altTag: 'Saatva HD Mattress',
        attributeSets: [],
        imageDescriptors: ['front', 'side', 'angle', 'saatvana', 'above']
    },
    [`${productCodes.saatvaRX}`]: {
        altTag: 'Saatva Rx Mattress',
        attributeSets: [],
        imageDescriptors: ['room-angle', 'room-front', 'room-side', 'above', 'silo-top', 'model']
    },
    [`${productCodes.latexHybrid}`]: {
        altTag: 'Saatva Latex Hybrid Mattress',
        attributeSets: [],
        imageDescriptors: ['side', 'angle', 'saatvana-side', 'saatvana-corner', 'above', 'front']
    },
    [`${productCodes.youth}`]: {
        altTag: 'Saatva Youth Mattress',
        attributeSets: [],
        imageDescriptors: ['front', 'detail', 'tween', 'girl', 'boy']
    },
    [`${productCodes.solaire}`]: {
        altTag: 'Solaire Adjustable Firmness Mattress',
        attributeSets: ['mattressType'],
        imageDescriptors: [
            'room-front',
            'room-angle-raised',
            'room-angle',
            {
                descriptorName: 'room-side',
                attributeSets: []
            },
            'room-above',
            {
                descriptorName: 'saatvana',
                attributeSets: []
            }
        ]
    },
    [`${productCodes.memoryFoamHybrid}`]: {
        altTag: 'Memory Foam Hybrid Mattress',
        attributeSets: [],
        imageDescriptors: ['angle', 'above', 'front', 'side', 'sweep']
    },
    [`${productCodes.loom}`]: {
        altTag: 'Loom & Leaf Mattress',
        attributeSets: [],
        imageDescriptors: ['side', 'angle', 'child', 'model', 'front']
    },
    [`${productCodes.contour5}`]: {
        altTag: 'Saatva Contour5 Mattress',
        attributeSets: [],
        imageDescriptors: ['room-angle', 'room-above', 'room-front', 'room-side', 'sweep']
    },
    [`${productCodes.crib}`]: {
        altTag: 'Crib Mattress',
        attributeSets: [],
        imageDescriptors: ['front', 'pillows', 'above', 'sweep', 'silo']
    },
    [`${productCodes.dogBed}`]: {
        altTag: 'Saatva Dog Bed',
        attributeSets: [],
        imageDescriptors: [
            {
                descriptorName: 'front-model',
                attributeSets: ['size']
            },
            {
                descriptorName: 'front',
                attributeSets: ['size', 'color']
            },
            {
                descriptorName: 'detail',
                attributeSets: ['color']
            },
            {
                descriptorName: 'colorway-front',
                attributeSets: []
            },
            {
                descriptorName: 'angle-model',
                attributeSets: ['size'],
                exclusions: {
                    size: ['small']
                }
            }
        ]
    },
    [`${productCodes.bunkAndTrundle}`]: {
        altTag: 'Bunk & Trundle Mattress',
        attributeSets: [],
        imageDescriptors: [
            `room-front`,
            `room-angle`,
            `lifestyle-front`,
            `lifestyle-angle`,
            `silo-top`
        ]
    },
    // Bedding Products
    [`${productCodes.microcoilMattressTopper}`]: {
        altTag: 'Micro-Coil Mattress Topper',
        attributeSets: [],
        imageDescriptors: ['lifestyle-corner', 'lifestyle-side']
    },
    [`${productCodes.aeroQuilt}`]: {
        altTag: 'Aero Quilt',
        attributeSets: ['color'],
        imageDescriptors: [
            {
                descriptorName: 'room-angle',
                attributeSets: ['color'],
                exclusions: {
                    color: ['white']
                }
            },
            {
                descriptorName: 'bed-top',
                attributeSets: ['color'],
                exclusions: {
                    color: ['sage', 'sand'] // handling both possible names for backwards compatibility
                }
            },
            {
                descriptorName: 'messy',
                attributeSets: ['color'],
                exclusions: {
                    color: ['indigo', 'white']
                }
            },
            'silo',
            {
                descriptorName: 'colorways',
                attributeSets: []
            },
            {
                descriptorName: 'lifestyle',
                attributeSets: ['color'],
                exclusions: {
                    color: ['white']
                }
            }
        ]
    },
    [`${productCodes.downAlternativeComforter}`]: {
        altTag: 'All-Year Down Alternative Comforter',
        attributeSets: [],
        imageDescriptors: ['room-front', 'room-angle', 'silo-top', 'detail', 'detail-corner', 'silo']
    },
    [`${productCodes.lightweightAlternativeComforter}`]: {
        altTag: 'Lightweight Down Alternative Comforter',
        attributeSets: [],
        imageDescriptors: ['room-front', 'room-angle', 'silo-top', 'detail', 'detail-corner', 'silo']
    },
    [`${productCodes.heavyweightComforter}`]: {
        altTag: 'Heavyweight Down Alternative Comforter',
        attributeSets: [],
        imageDescriptors: ['room-front', 'room-angle', 'silo-top', 'detail', 'detail-corner', 'silo']
    },
    [`${productCodes.downAlternativePillow}`]: {
        altTag: 'Down Alternative Pillow',
        attributeSets: [],
        imageDescriptors: [
            'silo',
            {
                isGeneric: true,
                descriptorName: 'pillows-stack',
                attributeSets: []
            },
            'side',
            'detail',
            'corner'
        ]
    },
    [`${productCodes.pillow}`]: {
        altTag: 'Saatva Latex Pillow',
        attributeSets: [],
        imageDescriptors: [
            'silo',
            {
                isGeneric: true,
                descriptorName: 'pillows-on-chest',
                attributeSets: []
            },
            'lifestyle-woman-sleeping',
            {
                descriptorName: 'side',
                attributeSets: ['pillowHeightOptions']
            },
            {
                descriptorName: 'detail',
                attributeSets: ['pillowHeightOptions']
            },
            'corner'
        ]
    },
    [`${productCodes.foamPillow}`]: {
        altTag: 'Graphite Memory Foam Pillow',
        attributeSets: [],
        imageDescriptors: [
            'silo',
            {
                isGeneric: true,
                descriptorName: 'pillows-on-chest',
                attributeSets: []
            },
            'side',
            'corner',
            'detail',
            {
                isGeneric: true,
                descriptorName: 'pillows-stack',
                attributeSets: []
            }
        ]
    },
    [`${productCodes.cloudFoamPillow}`]: {
        altTag: 'Cloud Memory Foam Pillow',
        attributeSets: [],
        imageDescriptors: [
            'silo',
            {
                isGeneric: true,
                descriptorName: 'pillows-on-chest',
                attributeSets: []
            },
            'side',
            'corner',
            'detail',
            {
                isGeneric: true,
                descriptorName: 'pillows-on-white',
                attributeSets: []
            }
        ]
    },
    [`${productCodes.organicQuiltedPillow}`]: {
        altTag: 'Organic Quilted Pillow',
        attributeSets: [],
        imageDescriptors: [
            'silo',
            'side',
            'corner',
            'detail',
            {
                isGeneric: true,
                descriptorName: 'pillows-stack',
                attributeSets: []
            },
            {
                isGeneric: true,
                descriptorName: 'pillows-on-white',
                attributeSets: []
            }
        ]
    },
    [`${productCodes.herringboneKnitBlanket}`]: {
        altTag: 'Herringbone Knit Blanket',
        attributeSets: ['color'],
        imageDescriptors: [
            'detail-angle',
            'messy',
            'room-angle',
            {
                descriptorName: 'colorways-stack',
                attributeSets: []
            },
            'silo'
        ]
    },
    [`${productCodes.waffleKnitBlanket}`]: {
        altTag: 'Waffle Knit Blanket',
        attributeSets: ['color'],
        imageDescriptors: [
            'room-angle',
            'lifestyle-chair',
            'detail',
            'room-profile',
            {
                descriptorName: 'colorways',
                attributeSets: []
            },
            'silo'
        ]
    },
    [`${productCodes.sweaterKnitBlanket}`]: {
        altTag: 'Sweater Knit Blanket',
        attributeSets: ['color'],
        imageDescriptors: [
            'room-angle',
            'lifestyle-chair',
            'detail',
            'room-side',
            {
                descriptorName: 'colorways',
                attributeSets: []
            },
            'silo'
        ]
    },
    [`${productCodes.weightedBlanket}`]: {
        altTag: 'Weighted Blanket',
        attributeSets: ['weightedBlanketColor'],
        imageDescriptors: [
            'on-sofa-draped',
            'model-sofa',
            'on-sofa-folded',
            'model-bed',
            {
                descriptorName: 'hanging',
                attributeSets: []
            }
        ]
    },
    [`${productCodes.diamondKnitBlanket}`]: {
        altTag: 'Diamond Knit Blanket',
        attributeSets: ['color'],
        imageDescriptors: [
            {
                descriptorName: 'room-angle',
                attributeSets: [],
                exclusions: {
                    color: 'sand'
                }
            },
            {
                descriptorName: 'room-front',
                attributeSets: [],
                exclusions: {
                    color: ['white', 'ash']
                }
            },
            'silo',
            {
                descriptorName: 'colorways-texture',
                attributeSets: []
            },
            {
                descriptorName: 'detail',
                attributeSets: []
            },
            {
                descriptorName: 'colorways-stacked',
                attributeSets: []
            }
        ]
    },
    [`${productCodes.sheets}`]: {
        altTag: 'Organic Sateen Sheet Set',
        attributeSets: ['color'],
        imageDescriptors: [
            'silo',
            'lifestyle-angle',
            'lifestyle-profile',
            'lifestyle-close-up',
            'detail-corner',
            'lifestyle'
        ]
    },
    [`${productCodes.percaleSheets}`]: {
        altTag: 'Percale Sheet Set',
        attributeSets: ['color'],
        imageDescriptors: [
            'silo',
            'lifestyle-angle',
            'lifestyle-profile',
            'lifestyle-close-up',
            'detail-corner',
            'lifestyle'
        ]
    },
    [`${productCodes.essentialSheetSet}`]: {
        altTag: 'Essential Sheet Set',
        attributeSets: ['color'],
        imageDescriptors: [
            'silo',
            'lifestyle-angle',
            'lifestyle-profile',
            'lifestyle-close-up',
            'detail-corner',
            'lifestyle'
        ]
    },
    [`${productCodes.linenSheets}`]: {
        altTag: 'Linen Sheet Set',
        attributeSets: ['color'],
        imageDescriptors: [
            'silo',
            'lifestyle-angle',
            'lifestyle-profile',
            'detail-corner',
            'lifestyle'
        ]
    },
    [`${productCodes.embroideredSateenSheet}`]: {
        altTag: 'Embroidered Sateen Sheet Set',
        attributeSets: ['color'],
        imageDescriptors: [
            'silo',
            'lifestyle-close-up',
            'lifestyle-angle',
            'detail-corner',
            {
                descriptorName: 'colorways'
            },
            'lifestyle'
        ]
    },
    [`${productCodes.bandedPercaleSheetSet}`]: {
        altTag: 'Banded Percale Sheet Set',
        attributeSets: ['color'],
        imageDescriptors: [
            'silo',
            'lifestyle-angle',
            'lifestyle-close-up',
            'detail-corner',
            'lifestyle',
            {
                descriptorName: 'lifestyle-profile',
                attributeSets: ['color'],
                exclusions: {
                    color: ['ash']
                }
            }
        ]
    },
    [`${productCodes.flannelSheetSet}`]: {
        altTag: 'Luxury Flannel Sheet Set',
        attributeSets: ['color'],
        imageDescriptors: [
            'silo',
            'lifestyle',
            'lifestyle-angle',
            'lifestyle-close-up',
            'detail-corner'
        ]
    },
    [`${productCodes.essentialSheetSet}`]: {
        altTag: 'Essential Sheet Set',
        attributeSets: ['color'],
        imageDescriptors: [
            'silo',
            'lifestyle-angle',
            'lifestyle-profile',
            'lifestyle-close-up',
            'detail-corner',
            'lifestyle'
        ]
    },
    [`${productCodes.signatureSheets}`]: {
        altTag: 'Signature Sateen Sheet Set',
        attributeSets: ['color'],
        imageDescriptors: [
            'silo',
            'lifestyle-angle',
            'lifestyle-profile',
            'lifestyle-close-up',
            'detail-corner',
            'lifestyle' 
        ]
    },
    [`${productCodes.organicCottonChannelQuilt}`]: {
        altTag: 'Organic Cotton Channel Quilt',
        attributeSets: ['color'],
        imageDescriptors: [
            {
                descriptorName: 'silo',
                attributeSets: ['quiltSet', 'color']
            },
            'room-angle',
            'messy',
            'room-front',
            {
                descriptorName: 'colorways',
                attributeSets: []
            },
            {
                descriptorName: 'sham-euro',
                attributeSets: ['color'],
                exclusions: {
                    quiltSet: 'quilt-only'
                }
            }
        ]
    },
    [`${productCodes.organicVelvetQuilt}`]: {
        altTag: 'Organic Velvet Diamond Quilt',
        attributeSets: ['color'],
        imageDescriptors: [
            {
                descriptorName: 'silo',
                attributeSets: ['quiltSet', 'color']
            },
            'room-angle',
            'messy',
            'room-front',
            {
                descriptorName: 'colorways',
                attributeSets: []
            },
            {
                descriptorName: 'sham-euro',
                attributeSets: ['color'],
                exclusions: {
                    quiltSet: 'quilt-only'
                }
            }
        ]
    },
    [`${productCodes.organicSateenDuvet}`]: {
        altTag: 'Organic Sateen Duvet Cover Set',
        attributeSets: ['color'],
        imageDescriptors: [
            'silo',
            'lifestyle',
            'lifestyle-angle',
            'lifestyle-profile',
            'room-angle',
            'detail'
        ]
    },
    [`${productCodes.percaleDuvet}`]: {
        altTag: 'Organic Percale Duvet Cover Set',
        attributeSets: ['color'],
        imageDescriptors: [
            'silo',
            'lifestyle',
            'lifestyle-angle',
            'lifestyle-profile',
            'room-angle',
            'detail'
        ]
    },
    [`${productCodes.linenDuvetCoverSet}`]: {
        altTag: 'Linen Duvet Cover Set',
        attributeSets: ['color'],
        imageDescriptors: [
            'silo',
            'lifestyle',
            'lifestyle-angle',
            'lifestyle-profile',
            'room-angle',
            'detail',
        ]
    },
    [`${productCodes.embroideredSateenDuvet}`]: {
        altTag: 'Embroidered Sateen Duvet Cover Set',
        attributeSets: ['color'],
        imageDescriptors: [
            'silo',
            'lifestyle',
            'lifestyle-angle',
            'room-angle',
            'detail',
            {
                descriptorName: 'lifestyle-profile',
                attributeSets: ['color'],
                exclusions: {
                    color: ['white']
                }

            }
        ]
    },
    [`${productCodes.bandedPercaleDuvetSet}`]: {
        altTag: 'Banded Percale Duvet Cover Set',
        attributeSets: ['color'],
        imageDescriptors: [
            'silo',
            'lifestyle',
            'lifestyle-angle',
            'lifestyle-profile',
            'detail',
        ]
    },
    [productCodes.essentialDuvetCoverSet]: {
        altTag: 'Essential Duvet Cover Set',
        attributeSets: ['color'],
        imageDescriptors: [
            'silo',
            'lifestyle',
            'lifestyle-angle',
            'lifestyle-profile',
            {
                descriptorName: 'room-angle',
                attributeSets: ['color'],
                exclusions: {
                    color: 'light-indigo'
                }
            },
            'detail',
            {
                descriptorName: 'lifestyle-detail',
                attributeSets: ['color'],
                exclusions: {
                    color: ['white', 'ash', 'ivory']
                }
            }
        ]
    },
    [productCodes.signatureDuvetCoverSet]: {
        altTag: 'Signature Duvet Cover Set',
        attributeSets: ['color'],
        imageDescriptors: [
            'silo',
            'lifestyle',
            'lifestyle-angle',
            'lifestyle-profile',
            'room-angle',
            'detail'
        ]
    },
    [`${productCodes.pad}`]: {
        altTag: 'Organic Mattress Pad',
        attributeSets: [],
        imageDescriptors: [
            'lifestyle-profile',
            'silo',
            'silo-folded'
        ]
    },
    [`${productCodes.mattressProtector}`]: {
        altTag: 'Waterproof Mattress Protector',
        attributeSets: [],
        imageDescriptors: [
            'lifestyle-corner',
            'silo',
            'silo-folded'
        ]
    },
    [`${productCodes.graphiteMemoryFoamTopper}`]: {
        altTag: 'Graphite Memory Foam Mattress Topper',
        attributeSets: [],
        imageDescriptors: [
            'lifestyle-side',
            'lifestyle-close-up',
            'lifestyle-setup',
            'silo-close-up'
        ]
    },
    [`${productCodes.highDensityFoamTopper}`]: {
        altTag: 'High-Density Foam Mattress Topper',
        attributeSets: [],
        imageDescriptors: [
            'lifestyle-side',
            'lifestyle-corner',
            'lifestyle-setup',
            'silo-close-up'
        ]
    },
    [`${productCodes.naturalLatexMattressTopper}`]: {
        altTag: 'Natural Latex Mattress Topper',
        attributeSets: [],
        imageDescriptors: [
            'lifestyle-side',
            'lifestyle-corner',
            'lifestyle-setup',
            'silo-close-up'
        ]
    },
    [`${productCodes.featherbedMattressTopper}`]: {
        altTag: 'Down Alternative Featherbed Mattress Topper',
        attributeSets: [],
        imageDescriptors: [
            'silo-profile',
            'silo-close-up',
            'lifestyle-setup',
            'lifestyle-detail'
        ]
    },
    [`${productCodes.organicQuiltedMattressTopper}`]: {
        altTag: 'Organic Quilted Mattress Topper',
        attributeSets: [],
        imageDescriptors: [
            'lifestyle-side',
            'silo-side',
            'lifestyle-corner'
        ]
    },
    [`${productCodes.weightedSilkEyeMask}`]: {
        altTag: 'Weighted Silk Eye Mask',
        attributeSets: ['color'],
        imageDescriptors: [
            'silo',
            'box-open',
            {
                descriptorName: 'colorways',
                attributeSets: []
            },
            {
                descriptorName: 'lifestyle-messy',
                attributeSets: []
            }
        ]
    },
    [`${productCodes.silkEyeMask}`]: {
        altTag: 'Silk Eye Mask',
        attributeSets: ['color'],
        imageDescriptors: [
            'silo',
            {
                descriptorName: 'lifestyle',
                attributeSets: ['color'],
                exclusions: {
                    color: ['sand', 'graphite']
                }
            },
            {
                descriptorName: 'colorways',
                attributeSets: []
            },
            {
                descriptorName: 'lifestyle-messy',
                attributeSets: []
            }
        ]
    },
    [`${productCodes.waffleKnitDuvetCoverSet}`]: {
        altTag: 'Waffle Knit Duvet Cover Set',
        attributeSets: ['color'],
        imageDescriptors: [
            'silo',
            'lifestyle',
            'lifestyle-angle',
            'lifestyle-profile',
            'lifestyle-close-up',
            'detail'
        ]
    },
    [`${productCodes.plushTowels}`]: {
        altTag: 'Plush Towels Collection',
        attributeSets: ['color'],
        imageDescriptors: [
            {
                descriptorName: 'silo',
                attributeSets: ['bathSet', 'color'] // also follows order in array
            },
            {
                descriptorName: 'lifestyle',
                attributeSets: []
            },
            'detail',
            {
                descriptorName: 'colorways',
                attributeSets: []
            }
        ]
    },
    [`${productCodes.waffleTowels}`]: {
        altTag: 'Waffle Towel Collection',
        attributeSets: ['color'],
        imageDescriptors: [
            {
                descriptorName: 'silo',
                attributeSets: ['bathSet', 'color']
            },
            {
                descriptorName: 'lifestyle',
                attributeSets: []
            },
            'detail',
            {
                descriptorName: 'colorways',
                attributeSets: []
            }
        ]
    },
    [`${productCodes.percalePillowcase}`]: {
        altTag: 'Percale Pillowcases',
        attributeSets: ['color'],
        imageDescriptors: [
            'lifestyle-messy',
            'folded-cross',
            'folded-close',
            'lifestyle-made',
            {
                descriptorName: 'silo',
                attributeSets: ['size', 'color']
            }
        ]
    },
    [`${productCodes.sateenPillowcase}`]: {
        altTag: 'Sateen Pillowcases',
        attributeSets: ['color'],
        imageDescriptors: [
            'lifestyle-messy',
            'folded-cross',
            'folded-close',
            'lifestyle-made',
            {
                descriptorName: 'silo',
                attributeSets: ['size', 'color']
            }
        ]
    },
    [`${productCodes.sateenCottonPillowcases}`]: {
        altTag: 'Signature Sateen Pillowcases',
        attributeSets: ['color'],
        imageDescriptors: [
            'lifestyle-messy',
            'folded-cross',
            'folded-close',
            'lifestyle-made',
            {
                descriptorName: 'silo',
                attributeSets: ['size', 'color']
            }
        ]
    },
    [`${productCodes.bandedPercalePillowcasePair}`]: {
        altTag: 'Banded Percale Pillowcase Pair',
        attributeSets: ['color'],
        imageDescriptors: [
            'lifestyle-messy',
            'folded',
            'lifestyle-made'
        ]
    },
    [`${productCodes.linenPillowcase}`]: {
        altTag: 'Linen Pillowcases',
        attributeSets: ['color'],
        imageDescriptors: [
            'lifestyle-messy',
            {
                descriptorName: 'folded-cross',
                attributeSets: ['color'],
                exclusions: {
                    color: ['terra']
                }
            },
            {
                descriptorName: 'folded-close',
                attributeSets: ['color'],
                exclusions: {
                    color: ['terra']
                }
            },
            {
                descriptorName: 'lifestyle',
                attributeSets: []
            }
        ]
    },
    [`${productCodes.essentialPillowcases}`]: {
        altTag: 'Essential Pillowcase Pair',
        attributeSets: ['color'],
        imageDescriptors: [
            'lifestyle-made',
            'lifestyle-messy'
        ]
    },
    [`${productCodes.embroideredSateenPillowCasePair}`]: {
        altTag: 'Embroidered Pillowcase Pair',
        attributeSets: ['color'],
        imageDescriptors: [
            'lifestyle',
            'bedroom',
            {
                descriptorName: 'colorways',
                attributeSets: []
            },
            {
                descriptorName: 'silo',
                attributeSets: ['size', 'color']
            }
        ]
    },
    [`${productCodes.flannelPillowcases}`]: {
        altTag: 'Flannel Pillowcase Pair',
        attributeSets: ['color'],
        imageDescriptors: [
            'lifestyle-made',
            'lifestyle-messy'
        ]
    },
    [`${productCodes.silkPillowcase}`]: {
        altTag: 'Silk Pillowcase',
        attributeSets: ['color'],
        imageDescriptors: [
            'silo',
            'lifestyle',
            {
                descriptorName: 'detail',
                attributeSets: []
            },
        ]
    },
    [`${productCodes.classicStarterBundle}`]: {
        altTag: 'Classic Starter Bundle',
        attributeSets: [],
        imageDescriptors: [
            'room-front',
            'room-side',
            'lifestyle',
            'room-above',
            'detail',
            'room-angle',
        ]
    },
    [`${productCodes.sateenSheetsLatexPillow}`]: {
        altTag: 'Sateen Sheet Set & Latex Pillows Bundle',
        attributeSets: ['color'],
        imageDescriptors: [
            {
                descriptorName: 'silo',
                attributeSets: []
            },
            'messy',
            'bed',
            'lifestyle-woman',
            'detail',
            {
                descriptorName: 'lifestyle-bed-side-table',
                attributeSets: []
            }
        ]
    },
    [`${productCodes.sateenSheetsLatexPillowPad}`]: {
        altTag: 'Sateen Sheet Set, Latex Pillows & Mattress Pad Bundle',
        attributeSets: ['color'],
        imageDescriptors: [
            {
                descriptorName: 'silo',
                attributeSets: []
            },
            'messy',
            'bed',
            'lifestyle-woman',
            'detail',
            {
                descriptorName: 'lifestyle-bed-side-table',
                attributeSets: []
            },
            {
                descriptorName: 'lifestyle-profile',
                attributeSets: []
            }
        ]
    },
    [`${productCodes.sateenSheetsMemoryFoamPillow}`]: {
        altTag: 'Sateen Sheet Set & Memory Foam Pillows Bundle',
        attributeSets: ['color'],
        imageDescriptors: [
            {
                descriptorName: 'silo',
                attributeSets: []
            },
            'messy',
            'bed',
            'lifestyle-woman',
            'detail',
            {
                descriptorName: 'front-single',
                attributeSets: []
            }
        ]
    },
    [`${productCodes.velvetPickStitchQuilt}`]: {
        altTag: 'Velvet Pick Stitch Quilt',
        attributeSets: ['color'],
        imageDescriptors: [
            'silo',
            'lifestyle',
            'lifestyle-angle',
            'detail'
        ]
    },
    [`${productCodes.ardenNightstand}`]: {
        altTag: 'Arden Nightstand',
        attributeSets: ['color'],
        imageDescriptors: [
            'front',
            'room-front',
            'open',
            'angle',
            'side'
        ]
    },
    [`${productCodes.hydeNightstand}`]: {
        altTag: 'Hyde Nightstand',
        attributeSets: ['size'],
        imageDescriptors: [
            'front',
            'room-front',
            'open',
            'angle',
            'side'
        ]
    },
    [`${productCodes.verdonNightstand}`]: {
        altTag: 'Verdon Nightstand',
        attributeSets: ['size'],
        imageDescriptors: [
            'front',
            'room-front',
            'open',
            'angle',
            'side'
        ]
    }
}

export default carouselData

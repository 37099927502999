import { useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'

import { DetailProductTile } from '@saatva-bits/pattern-library.components.detail-product-tile'
import { SimpleSlider } from '@saatva-bits/pattern-library.components.simple-slider'
import { useGetDiscounts } from '@saatva-bits/pattern-library.modules.promotions'
import { useProductState, useAttributeOptions } from '@saatva-bits/pattern-library.modules.selection'
import { useBreakpoints } from '@saatva-bits/pattern-library.hooks.use-breakpoints'

import { getDetailTileArdadImages, getSwatches } from '@/utils/product'
import { getBadgeText } from '@/utils/discount'
import { affirmRefresh } from '@/utils/affirm'

import styles from './DetailProductTileSlider.module.scss'

const handleAffirmRefresh = (refresh) => {
    if (refresh) {
        affirmRefresh(100)
    }
}

const ProductTile = ({
    product,
    customImageProps,
    showAffirm,
    hideBadge,
    badgeTextVersion
}) => {
    const productCode = product.productCode

    const { getDiscounts } = useGetDiscounts()
    const productState = useProductState(productCode, ['assets', 'genericName', 'sku'])
    const { isTablet } = useBreakpoints('tablet')

    const { lowestPrice, highestPrice } = product

    const baseProduct = {
        sku: product.sku,
        parentSku: product.parentSku || product.sku,
        category: product.category,
        quantity: 1,
        isPrimaryProduct: true
    }

    const { finalPrice: lowestPriceDiscount } = getDiscounts([{ ...baseProduct, price: lowestPrice }])
    const { finalPrice: highestPriceDiscount, discountAmount: highestDiscountAmount } = getDiscounts([{ ...baseProduct, price: highestPrice }])

    const priceRange = {
        fullPriceMin: lowestPrice,
        fullPriceMax: highestPrice,
        finalPriceMin: lowestPriceDiscount || 0,
        finalPriceMax: highestPriceDiscount || 0,
    }

    const title = product.title
    const subtitle = product.subtitle

    const options = useAttributeOptions(productCode, product.swatchAttribute)

    const onSwatchChange = (swatch) => {
        options.setAttributeValue(swatch)
    }

    const swatches = getSwatches(options, options?.selectedValue, onSwatchChange, productCode)
    const { defaultImage, hoverImage } = getDetailTileArdadImages(product.ardadDescriptors, productState, '3-2', customImageProps)
    const badgeText = getBadgeText(highestDiscountAmount, badgeTextVersion)

    return (
        <DetailProductTile
            containerClassName={styles.tile}
            title={{ text: title, href: product.url, titleClassName: styles.title }}
            subtitle={{ text: subtitle, className: styles.subtitle }}
            imageHref={product.url}
            priceRange={priceRange}
            swatches={swatches}
            defaultImage={defaultImage}
            hoverImage={hoverImage}
            imageBadgeLeft={badgeText && !hideBadge ? { text: badgeText, kind: 'promo', position: 'topLeft' } : undefined}
            affirm={showAffirm && !isTablet && { price: lowestPriceDiscount }}
        />
    )
}

const DetailProductTileSlider = ({
    products,
    title,
    sectionClassName,
    titleClassName,
    dataSelector,
    isCart = false,
    simpleSliderProps = {},
    customImageProps,
    showAffirm = false,
    hideBadge = false,
    badgeTextVersion = 'exact'
}) => {
    useEffect(() => {
        handleAffirmRefresh(showAffirm)
    })

    if (isEmpty(products)) {
        return null
    }

    const sliderContent = products.map(product => (
        <ProductTile
            key={product.productCode}
            product={product}
            customImageProps={customImageProps}
            showAffirm={showAffirm}
            hideBadge={hideBadge}
            badgeTextVersion={badgeTextVersion}
        />
    ))

    const hideSliderControlsStyles = classNames({ [styles.hideSliderControls]: products.length < 4 })
    const leftButtonClasses = classNames(styles.buttons, styles.leftButton, hideSliderControlsStyles)
    const rightButtonClasses = classNames(styles.buttons, styles.rightButton, hideSliderControlsStyles)
    const pageDtosAndNumbersClasses = classNames(styles.paginationDots, hideSliderControlsStyles)

    const sliderProps = {
        isCart,
        sliderLabel: dataSelector,
        infinite: true,
        pageDotsAndNumbers: true,
        dotsClassName: pageDtosAndNumbersClasses,
        leftButtonClassName: leftButtonClasses,
        rightButtonClassName: rightButtonClasses,
        ...simpleSliderProps
    }

    const sectionClassNames = classNames('section', styles.section, sectionClassName)
    const titleClassNames = classNames('t-heading2 t-color', styles.sectionTitle, titleClassName)

    return (
        <section className={sectionClassNames} data-selector={dataSelector}>
            <div className="container">
                {title &&
                    <h2 className={titleClassNames}>
                        {title}
                    </h2>
                }
                <div className={styles.mobileSlider}>
                    <SimpleSlider
                        shownSize={1}
                        overflow={'hidden'}
                        onChange={() => handleAffirmRefresh(showAffirm)}
                        {...sliderProps}
                    >
                        {sliderContent}
                    </SimpleSlider>
                </div>
                <div className={styles.defaultSlider}>
                    <SimpleSlider
                        shownSize={3}
                        overflow={'hidden'}
                        onChange={() => handleAffirmRefresh(showAffirm)}
                        {...sliderProps}
                    >
                        {sliderContent}
                    </SimpleSlider>
                </div>
            </div>
        </section>
    )
}

DetailProductTileSlider.propTypes = {
    products: PropTypes.array.isRequired,
    title: PropTypes.string,
    sectionClassName: PropTypes.string,
    titleClassName: PropTypes.string,
    dataSelector: PropTypes.string,
    isCart: PropTypes.bool,
    hasSwatches: PropTypes.bool,
    simpleSliderProps: PropTypes.object,
    showAffirm: PropTypes.bool,
    hideBadge: PropTypes.bool
}

export default DetailProductTileSlider

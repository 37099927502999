
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { FormCheckbox } from '@saatva-bits/pattern-library.components.form-checkbox'
import { useFiltersData } from '@/contexts/filters'
import { applyVariantFiltering } from '@/utils/filters'
import { useFeatureFlag } from '@saatva-bits/pattern-library.modules.launch-darkly'

const createFilterLink = (pathname, filterKey, filterValue, sortValue) => {
    const parameters = new URLSearchParams()

    parameters.set(filterKey, filterValue)
    sortValue && parameters.set('sorting', sortValue)

    return `${pathname}${parameters.toString().length > 0 ? '?' : ''}${parameters}`
}

const calculateFilterQuantity = (products, property, filter, selectedFilters) => {
    if (!products || !property || !filter || !selectedFilters) {
        return 0
    }

    const newFilters = selectedFilters.map(selected => {
        const filtersAdded = Object.assign({}, selected)
        let matchingValues = selected.matchingValues
        if (selected.property === property) {
            matchingValues = [...filter.matchingValues]
        }
        filtersAdded.matchingValues = matchingValues
        return filtersAdded
    })

    const filteredProducts = applyVariantFiltering(products, newFilters, null)
    return filteredProducts.length
}

const FormCheckboxGroup = ({
    className,
    dataSrc,
    name = '',
    initialValues,
    required = 0,
    onSelectionUpdate = () => { },
    checkboxClassName,
    sortValue = '',
    filter,
    products,
    selectedFilters,
    isModal = false,
    ...other
}) => {
    const { isOn: isLampsInNavDisabled } = useFeatureFlag('DISABLE_LAMPS_IN_NAV.EX-386')
    const { category } = useFiltersData()

    const [state, setState] = useState({
        selectedValues: initialValues,
        isValid: true
    })

    useEffect(() => {
        if (initialValues)
            setState({
                selectedValues: initialValues,
                isValid: true
            })
    }, [initialValues])


    const handleInputChange = (e) => {
        const target = e.target ? e.target : e
        const value = target.value

        if (!target.checked) {
            const selectedArr = [...state.selectedValues]

            const index = selectedArr.indexOf(value)
            selectedArr.splice(index, 1)

            setState({
                selectedValues: selectedArr,
                isValid: selectedArr.length >= required
            })
        } else {
            setState({
                isValid: state.selectedValues.length + 1 >= required,
                selectedValues: [...state.selectedValues, value]
            })
        }

        onSelectionUpdate({
            selectedValues: state.selectedValues,
            lastToggled: value,
            isValid: state.isValid
        })
    }

    const classes = classNames(className)

    const checkboxes = dataSrc.map((data, i) => {
        const quantity = calculateFilterQuantity(products, filter.property, data, selectedFilters)
        let label = data.label
        if (quantity > 0) {
            label = !data.disabled ? `${data.label} (${quantity})` : data.label
        }
        /** TODO: Remove this after lamps are launched */
        if (isLampsInNavDisabled && label === 'Lighting') {
            return null
        }
        return (
            <a href={createFilterLink(category, filter.property, data.value, sortValue)} key={`anchor-${filter.property}-${data.value}`} data-selector={`check_box_filter_${data.value}`}>
                <FormCheckbox
                    {...data}
                    key={`${i}`}
                    id={`${isModal ? 'modal' : 'regular'}_${data.value}`}
                    className={checkboxClassName}
                    name={name}
                    onChange={handleInputChange}
                    checked={state.selectedValues.indexOf(data.value) !== -1}
                    label={label}
                    disabled={data.disabled || quantity === 0}
                />
            </a>
        )
    })

    return (
        <div className={classes} {...other}>
            {checkboxes}
        </div>
    )
}

FormCheckboxGroup.propTypes = {
    /** Applies a custom class to the FormTextInput component's wrapping div. */
    className: PropTypes.string,
    /** Array of values to be showed as checkboxes. */
    dataSrc: PropTypes.array.isRequired,
    /** Array of selected values. */
    initialValues: PropTypes.array,
    /** Checkboxe's name attribute. */
    name: PropTypes.string,
    /** Prop that controls whether the input is a required field. */
    required: PropTypes.number,
    /** Handler to manage the checkbox selection. */
    onSelectionUpdate: PropTypes.func,
    /** Passing link for SEO purposes */
    sortValue: PropTypes.string,
    /** Passing filters for link  */
    filter: PropTypes.any,
    /** Passing selected filters  */
    selectedFilters: PropTypes.any,
    /** Passing products array */
    products: PropTypes.array,
    /** Needed to generate checkbox id */
    isModal: PropTypes.bool
}

export default FormCheckboxGroup

import React from 'react'
import PropTypes from 'prop-types'

import { FaqSection } from '@saatva-bits/pattern-library.modules.faq'

import ValuePropsSection from '@/components/ValuePropsSection'
import ReviewSection from '@/components/ReviewSection/ReviewSection'
import SupportValueProps from '@/components/SupportValueProps'

import ValuePropCards from './ValuePropCards/ValuePropCards'

const BeddingContent = ({ category, reviews }) => {
    return (
        <>
            <ReviewSection reviews={reviews} />
            <ValuePropCards />
            <ValuePropsSection configKey={category} background="contrast-1" />
            <SupportValueProps />
            <FaqSection />
        </>
    )
}

BeddingContent.propTypes = {
    category: PropTypes.string.isRequired
}

export default BeddingContent
